// Import custom components
import {
    ALERT, ALERT_CLOSE,
    PRELOADER
} from '../constants/actionType';

let initialState = {
    preloader: false,
    alert: {
        type: 'success',
        state: false,
        text: '',
    },
};

/**
 * A reducer takes two arguments, the current state and an action.
 */
const commonReducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case PRELOADER:
            return Object.assign({}, state, {
                preloader: action.show,
            });
        case ALERT:
            return Object.assign({}, state, {
                alert: {
                    state: !state.alert.state,
                    type: action.alertType,
                    text: action.alertText,
                },
            });
        case ALERT_CLOSE:
            return Object.assign({}, state, {
                alert: {
                    state: false,
                    type: 'success',
                    text: '',
                },
            });

        default:
            return state;
    }
};

export default commonReducer;