import React  from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { withStyles } from '@mui/styles';

const styles = () => ({
    loader: {
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        top: 0,
        bottom: 0,
        width: '100%',
        zIndex: 9999,
        backgroundColor: 'transparent',
        transition: '0.4s all ease',
    }
});

const Loader = (props) => {
    const { classes } = props;
    const preloader = useSelector(state => state.common.preloader);

    return preloader && (
        <div className={classes.loader}>
            <CircularProgress />
        </div>
    );
};

Loader.propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.element,
};

export default withStyles(styles)(Loader);
