// Import custom components
import {
    IMPORT_ID,
    IMPORT_LIST
} from '../constants/actionType';

let initialState = {
    list: {data: []},
    import: {
        id: null,
        name: null
    },
};

/**
 * A reducer takes two arguments, the current state and an action.
 */
const importReducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case IMPORT_LIST:
            return Object.assign({}, state, {
                list: action.data,
            });
        case IMPORT_ID:
            return Object.assign({}, state, {
                import: {
                    id: action.id,
                    name: action.name,
                }
            });

        default:
            return state;
    }
};

export default importReducer;
