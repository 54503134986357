import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import * as authService from '../../../services/authService';
import { amber } from '@mui/material/colors';
import {
    AppBar,
    Button,
    IconButton,
    Menu,
    Toolbar,
    Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';

const drawerWidth = 250;

const styles = theme => ({
    appBar: {
        position: 'absolute',
        zIndex: theme.zIndex.navDrawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: '100%',
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginLeft: -15,
        color: amber['50'],
    },
    flex: {
        flex: 1
    },
    logOutButton: {
        color: amber['50'],
    }
});

class Header extends Component {

    logOut(e) {
        e.preventDefault();
        this.props.actions.logout();
    }

    render() {
        const {classes} = this.props;

        return (
            <div>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton aria-label="Menu"
                                    className={classes.menuButton}>
                            <Menu open={false} />
                        </IconButton>
                        <Typography type="title" color="inherit" className={classes.flex}>

                        </Typography>
                        <Button className={classes.logOutButton} onClick={this.logOut.bind(this)}>Logout</Button>
                    </Toolbar>
                </AppBar>
            </div>
        );
    }
}

Header.propTypes = {
    classes: PropTypes.object.isRequired,
    actions: PropTypes.object
};

/**
 * Map the actions to props.
 */
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Object.assign({}, authService), dispatch)
});

export default connect(null, mapDispatchToProps)(withStyles(styles)(Header));
