import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import store, { history } from './store/configureStore';
import { verifyToken } from './services/tokenService';
import App from './containers/app/AppContainer';
import { blue } from '@mui/material/colors';
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { GoogleOAuthProvider } from '@react-oauth/google';

const mountNode = document.getElementById('root');

const theme = createTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: blue,
  },
});

// Used to log in if token is valid
store.dispatch(verifyToken());

ReactDOM.render(
  <Suspense fallback={<div>Error! Please refresh the page</div>}>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <GoogleOAuthProvider clientId="807828196228-k1og5a2ufr1jbkpu00elbs43o2afursi.apps.googleusercontent.com">
          <ConnectedRouter history={history}>
            <App />
          </ConnectedRouter>
        </GoogleOAuthProvider>
      </Provider>
    </ThemeProvider>
  </Suspense>,
  mountNode
);
