import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { alertClose } from '../../../actions/commonAction';
import {
    Alert,
    AlertTitle,
    Snackbar
} from '@mui/material';

const AlertDialog = () => {
    const dispatch = useDispatch();
    const onClose = useCallback(() => {
        dispatch(alertClose());
    }, [alertClose, dispatch]);

    const alert = useSelector(state => state.common.alert);
    const title = alert.type.charAt(0).toUpperCase() + alert.type.slice(1);
    const autoHideDuration = alert.type.toString() === 'success' ? 5000 : null;
    const anchorOrigin = { vertical: 'top', horizontal: 'center' };

    return (
        <Snackbar autoHideDuration={autoHideDuration} onClose={onClose} open={alert.state} anchorOrigin={anchorOrigin}>
            <Alert onClose={onClose} severity={alert.type} sx={{ width: '100%' }}>
                <AlertTitle>{title}</AlertTitle>
                {alert.text}
            </Alert>
        </Snackbar>
    );
};

export default AlertDialog;
