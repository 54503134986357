import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';

// Import custom components
import authReducer from './authReducer';
import commonReducer from './commonReducer';
import scannerReducer from './scannerReducer';
import importReducer from './importReducer';

const appReducer = (history) => combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    imports: importReducer,
    scanner: scannerReducer,
    common: commonReducer,
});

const rootReducer = (state, action) => {

    if (action === 'LOG_OUT_SUCCESS') {
        state = undefined;
    }

    return appReducer(state, action);
};

export default rootReducer;
