import {
    SCANNER_PROCESSES,
    SCANNER_PROCESSES_CLEAR,
    SCANNER_STATUS_RESULT,
    SCANNER_STATUS_RESULT_CLEAR
} from '../constants/actionType';

let initialState = {
    processes: null,
    status: null,
    increment: 0,
};

/**
 * A reducer takes two arguments, the current state and an action.
 */
const scannerReducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case SCANNER_PROCESSES:
            return Object.assign({}, state, {
                processes: action.data,
            });
        case SCANNER_PROCESSES_CLEAR:
            return Object.assign({}, state, {
                processes: null,
            });
        case SCANNER_STATUS_RESULT:
            return Object.assign({}, state, {
                status: action.status,
                increment: action.status === null ? state.increment + 1 : state.increment,
            });
        case SCANNER_STATUS_RESULT_CLEAR:
            return Object.assign({}, state, {
                status: null,
                increment: 0,
            });

        default:
            return state;
    }
};

export default scannerReducer;