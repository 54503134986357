export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS';
export const LOG_IN_FAILURE = 'LOG_IN_FAILURE';
export const LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS';

export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_FAILURE = 'SIGN_UP_FAILURE';

export const IMPORT_LIST = 'IMPORT_LIST';
export const IMPORT_ID = 'IMPORT_ID';

export const PRELOADER = 'PRELOADER';

export const ALERT = 'ALERT';
export const ALERT_CLOSE = 'ALERT_CLOSE';

// Scanner
export const SCANNER_PROCESSES = 'SCANNER_PROCESSES';
export const SCANNER_PROCESSES_CLEAR = 'SCANNER_PROCESSES_CLEAR';
export const SCANNER_STATUS_RESULT = 'SCANNER_STATUS_RESULT';
export const SCANNER_STATUS_RESULT_CLEAR = 'SCANNER_STATUS_RESULT_CLEAR';
