import {
    ALERT,
    ALERT_CLOSE,
    PRELOADER
} from '../constants/actionType';

export const preloader = (show) => {
    return {
        type: PRELOADER,
        show
    };
};

export const alert = (alertText = '', alertType = 'success') => {
    return {
        type: ALERT,
        alertType: alertType,
        alertText: alertText,
    };
};

export const alertClose = () => {
    return {
        type: ALERT_CLOSE,
    };
};