import axios from 'axios';
import { push } from 'connected-react-router';

import { loginSuccess, loginFailure, logoutSuccess } from '../actions/authAction';
import { API_URL, JWT_TOKEN } from '../config/config';
import { setLocalStorage, clearLocalStorage } from '../utils/storageUtil';
import { alertClose } from '../actions/commonAction';

export const login = ({ code }) => {
  return (dispatch) => {
    axios
      .post(API_URL + 'auth/login', { code })
      .then((response) => {
        dispatch(loginSuccess(response.data.token));
        setLocalStorage(JWT_TOKEN, response.data.token);
        dispatch(push('/list'));
        dispatch(alertClose());
      })
      .catch((error) => {
        dispatch(loginFailure(error.response && error.response.data ? error.response.data : error.toString()));
      });
  };
};

export const logout = () => {
  return (dispatch) => {
    clearLocalStorage(JWT_TOKEN);
    dispatch(logoutSuccess());
    dispatch(push('/'));

    return false;
  };
};
