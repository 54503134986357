import React, { Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import loadable from '@loadable/component';

// Import custom components
import PrivateRoute from './PrivateRoute';
import RestrictRoute from './RestrictRoute';
import MainLayout from '../components/common/layout/MainLayout';
import NotFound from '../components/error/NotFound';
import Loader from '../components/common/layout/Loader';

const AsyncDashboard = loadable(() => import('../containers/dashboard/DashboardContainer'));
const Login = loadable(() => import('../containers/auth/LoginContainer'));

const Router = () => (
  <Fragment>
    <Switch>
      <RestrictRoute exact path="/" layout={MainLayout} component={Login} />
      <PrivateRoute exact path="/list" layout={MainLayout} component={AsyncDashboard} />

      <Route component={NotFound} />
      <Loader />
    </Switch>
  </Fragment>
);

export default Router;
